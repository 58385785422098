import { Collection, hermes } from '@byll/hermes'
import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { Button } from 'components/Form/components/Button'
import { Message } from 'components/Message'
import { IBuildingFloor } from 'contracts/accommodations/interfaces/IBuildingFloor'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { uniqueId } from 'helpers/uniqueId'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { EditBuildingFloorRow } from './EditBuildingFloorRow'

interface Props {
  onClose: () => void
  floors: Collection<IBuildingFloor>
  buildingId: string
}

@observer
export class EditBuildingFloorsPrompt extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private readonly floors: (IBuildingFloor & { isDeleted: boolean })[]
  @observable private error: string | null = null
  @observable private saving = false

  constructor(props: Props) {
    super(props)
    this.floors = (props.floors.resources || [])
      .filter((r) => !!r.data)
      .map((r) => ({ ...r.data!, isDeleted: false }))
    makeObservable(this)
  }

  @action private addFloor = () => {
    this.floors.push({
      id: uniqueId('new-'),
      buildingId: this.props.buildingId,
      label: '',
      documentId: null,
      legend: {
        rooms: [],
        labels: [],
      },
      isDeleted: false,
    })
  }

  private onSubmit = async () => {
    if (this.saving) {
      return
    }

    // Trim floor labels
    runInAction(() => {
      for (const floor of this.floors) {
        floor.label = floor.label.trim()
      }
    })

    // Check all floors for valid labels
    for (const floor of this.floors) {
      if (!floor.label && !floor.isDeleted) {
        runInAction(() => (this.error = 'Bitte geben Sie allen Stockwerken einen Namen.'))
        return
      }
    }

    // Check for duplicate floor labels
    const floorLabels = new Set<string>()
    for (const floor of this.floors) {
      if (floor.isDeleted) {
        continue
      }
      if (floorLabels.has(floor.label.toLowerCase())) {
        runInAction(
          () => (this.error = 'Alle Stockwerke müssen unterschiedliche Namen haben.'),
        )
        return
      }
      floorLabels.add(floor.label.toLowerCase())
    }

    // Check all floors for switching labels (floor 1 had label 'A', now floor 2 has label 'A')
    // -> This would lead to problems with the unique index on the label during the update.
    // Also users could think that renaming in this way would reorder the floors. That is not
    // correct because than the rooms would simply be attached to the wrong floors.
    for (const floor of this.floors) {
      if (
        !floor.isDeleted &&
        (this.props.floors.resources || []).find(
          (f) =>
            !!f.data &&
            f.data.label.toLowerCase() === floor.label.toLowerCase() &&
            f.data.id !== floor.id,
        )
      ) {
        runInAction(
          () =>
            (this.error =
              'Ein Stockwerk darf nicht in einen Namen umbenannt werden, der zuvor von einem anderen Stockwerk belegt war. Falls Sie beabsichtigen, die Reihenfolge der Stockwerke zu ändern, ist dies nicht der richtige Weg. Bitte wenden Sie sich in diesem Fall an einen Administrator.'),
        )
        return
      }
    }

    try {
      runInAction(() => (this.saving = true))

      // Delete all floors that are to be deleted
      await Promise.all(
        this.floors
          .filter((f) => f.isDeleted && !f.id.startsWith('new-'))
          .map((f) =>
            hermes.delete(
              `/api/${this.context.instance.id}/accommodations/floors/${f.id}`,
            ),
          ),
      )

      // Update all floors that are to be updated
      await Promise.all(
        this.floors
          .filter((f) => {
            const original = hermes.getFromStore<IBuildingFloor>(
              `/api/${this.context.instance.id}/accommodations/floors/${f.id}`,
              false,
            )
            return !!original && f.label !== original.label
          })
          .map((f) =>
            hermes.patch(
              `/api/${this.context.instance.id}/accommodations/floors/${f.id}`,
              { label: f.label },
            ),
          ),
      )

      // Create all floors that are to be created
      await Promise.all(
        this.floors
          .filter((f) => !f.isDeleted && f.id.startsWith('new-'))
          .map((f) =>
            hermes.create(`/api/${this.context.instance.id}/accommodations/floors`, {
              buildingId: f.buildingId,
              label: f.label,
              imageId: null,
              legend: { rooms: [], labels: [] },
            }),
          ),
      )
      this.props.onClose()
    } catch (e: any) {
      runInAction(() => {
        this.error =
          e.id === ConflictError.id
            ? e.message
            : 'Es konnten nicht alle Änderungen gespeichert werden.'
        this.saving = false
      })
    }
  }

  render() {
    return (
      <>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Stockwerke bearbeiten
            </Dialog.Title>
          </div>
        </div>

        {this.error && (
          <Message color='danger' className='mt-4'>
            {this.error}
          </Message>
        )}

        <div className='mb-6 mt-4 text-sm'>
          Falls Sie ein Stockwerk zum Löschen vormerken (Papierkorbsymbol), so wird es
          erst unwiderruflich gelöscht, sobald Sie unten im Dialog auf{' '}
          <i>Änderungen speichern</i> klicken. Zugeordnete Räume bleiben aber erhalten.
          Sie verlieren dann lediglich ihre Stockwerkszuordnung.
        </div>

        <div className='flex flex-col gap-4'>
          {this.floors.map((floor) => (
            <EditBuildingFloorRow key={floor.id} floor={floor} />
          ))}

          <Button color='secondary' outline onClick={this.addFloor}>
            <i className='fas fa-plus' /> Stockwerk hinzufügen
          </Button>

          <div className='text-right'>
            <Button color='secondary' outline onClick={() => this.props.onClose()}>
              Abbrechen
            </Button>
            <Button
              color='primary'
              disabled={this.saving}
              className='ml-2'
              onClick={this.onSubmit}
            >
              Änderungen speichern
            </Button>
          </div>
        </div>
        {this.saving && <DialogOverlaySpinner opaque />}
      </>
    )
  }
}
