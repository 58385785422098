import { AppContextProps } from 'services/connection/models/AppContext'

export function getEmptySearchFilter(
  context: AppContextProps,
  responsibleCompoundId: string,
  resultsPerPage: number,
) {
  return {
    guiUrl: '/residents/find',
    deleted: context.permissions.host_lfgb ? null : 'no',
    searchEmpty: 'yes',
    searchString: '',
    page: `0,${resultsPerPage}`,
    sort: null,
    view: context.defaults.residentSearch.view ?? 'tile',
    fields: context.defaults.residentSearch.fields ?? '',

    responsibleCompoundId,
    responsibleScope: context.permissions.host_lfgb ? 'active' : null,
    visit: null,

    compoundId: null,
    buildingId: null,
    checkedIn: 'anytime',
    checkedInBegin: null,
    checkedInEnd: null,
    nationalityId: '', // can be comma separated list of ids
    languageIds: '',
    onlyKriegsfluchtUkr: null,
    onlyHv: null,
    todoText: null,
    todoState: 'open',
    dateType: null,
    dateState: 'set',
    dateBegin: null,
    dateEnd: null,
    ageBracket: '',
    lfgSchutzbeduerftig: '',
    costCoveragePayer: '', // can be comma separated list of ids
    billingStatus: null, // Awum billing status
  }
}
