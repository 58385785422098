import { classNames } from 'helpers/classNames'
import { uniqueId } from 'helpers/uniqueId'
import { action, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import Select, { MultiValue, StylesConfig } from 'react-select'
import { Model } from '../../Model'
import { getMultiselectAdded } from './helpers/getMultiselectAdded'
import { getMultiselectRemoved } from './helpers/getMultiselectRemoved'
import styles from './styles.module.scss'

export type InputMultiSelectOption = { id: string; label: string }

interface Props {
  name: string
  model: Model<any> // model.values[name] can be a string "1,4,2" or an array ["1", "4", "7"]. If it is an array, a new array is created on every change (immutablejs)
  options: InputMultiSelectOption[]
  id?: string
  label?: string
  className?: string
  placeholder?: string
  disabled?: boolean
  children?: Element
  onChange?: (added: InputMultiSelectOption[], removed: InputMultiSelectOption[]) => void
  styles?: StylesConfig<any>
  error?: boolean
}

interface IOption {
  value: string
  label: string
}

const colourStyles: StylesConfig<any> = {
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    minHeight: '36px',
    backgroundColor: state.isSelected ? '#6366f1' : state.isFocused ? '#e0e7ff' : 'white',
    color: state.isSelected ? 'white' : 'black',
    '&:hover': {
      backgroundColor: state.isSelected ? undefined : '#e0e7ff',
    },
  }),
  control: (styles, options) => ({
    ...styles,
    'input:focus': {
      boxShadow: 'none',
    },
    borderRadius: '0.375rem',
    cursor: 'text',
    boxShadow: options.isFocused
      ? '0 0 0 1px rgb(99, 102, 241)'
      : '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    fontSize: '14px',
    border: options.isFocused
      ? '1px solid rgb(99, 102, 241) !important'
      : '1px solid rgb(212, 212, 212) !important',
    color: '#000000',
  }),
  multiValue: (provided) => {
    return { ...provided, lineHeight: '22px', borderRadius: '4px' }
  },
}

const errorStyles: StylesConfig<any> = {
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    minHeight: '36px',
    backgroundColor: state.isSelected ? '#6366f1' : state.isFocused ? '#e0e7ff' : 'white',
    color: state.isSelected ? 'white' : 'black',
    '&:hover': {
      backgroundColor: state.isSelected ? undefined : '#e0e7ff',
    },
  }),
  control: (styles, options) => ({
    ...styles,
    'input:focus': {
      boxShadow: 'none',
    },
    borderRadius: '0.375rem',
    cursor: 'text',
    boxShadow: options.isFocused ? '0 0 0 1px rgb(239, 68, 68)' : 'none',
    fontSize: '14px',
    border: '1px solid rgb(239, 68, 68) !important',
    color: '#000000',
  }),
  multiValue: (provided) => {
    return { ...provided, lineHeight: '22px', borderRadius: '4px' }
  },
}

const DropdownIndicator = () => {
  return <div className={styles.dropdownIndicator} />
}

@observer
export class InputMultiSelect extends React.Component<Props, {}> {
  private readonly id: string
  readonly options: IOption[]

  private get value(): IOption[] {
    let ids: string[] = []
    if (this.props.model.values[this.props.name]) {
      ids =
        typeof this.props.model.values[this.props.name] === 'string'
          ? this.props.model.values[this.props.name].split(',')
          : this.props.model.values[this.props.name]
    }
    const selected: IOption[] = []
    for (const id of ids) {
      const option = this.options.find((o) => o.value === id)
      if (option) {
        selected.push(option)
      }
    }
    return selected
  }

  constructor(props: Props) {
    super(props)
    this.id = props.id || uniqueId('input-')
    this.options = Array.isArray(props.options)
      ? props.options.map((o) => ({ value: o.id, label: o.label }))
      : []
    makeObservable(this)
  }

  @action
  private onChange = (selected: MultiValue<IOption>) => {
    let oldIds = new Set<string>()
    if (
      !this.props.model.values[this.props.name] ||
      typeof this.props.model.values[this.props.name] === 'string'
    ) {
      if (this.props.model.values[this.props.name]) {
        oldIds = new Set(this.props.model.values[this.props.name].split(','))
      }
      this.props.model.values[this.props.name] = selected.map((o) => o.value).join(',')
    } else {
      oldIds = new Set(this.props.model.values[this.props.name])
      this.props.model.values[this.props.name] = selected.map((o) => o.value)
    }
    this.props.onChange?.(
      getMultiselectAdded(oldIds, selected),
      getMultiselectRemoved(oldIds, selected, this.props.options),
    )
  }

  render() {
    const { name, label, className, placeholder, disabled, error } = this.props

    return (
      <div className={classNames('relative', className)}>
        {label && (
          <label
            htmlFor={this.id}
            className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400'
            style={{ left: 9, top: -7, zIndex: 1 }}
          >
            {label}
          </label>
        )}
        <Select
          id={this.id}
          options={this.options}
          isMulti
          value={this.value}
          onChange={this.onChange}
          name={name}
          styles={this.props.styles || (error ? errorStyles : colourStyles)}
          isClearable={false}
          components={{ DropdownIndicator }}
          noOptionsMessage={() => 'Keine Ergebnisse'}
          placeholder={placeholder || ''}
          isDisabled={disabled}
        />
      </div>
    )
  }
}
