import * as React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Button } from 'components/Form/components/Button'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { InputCompound } from 'components/Form/components/InputCompound'
import { InputBuilding } from 'components/Form/components/InputBuilding'
import { InputDate } from 'components/Form/components/InputDate'
import { action, makeObservable } from 'mobx'
import { hermes } from '@byll/hermes'
import { AppContext } from 'services/connection/models/AppContext'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'

interface Props {
  model: Model<IResidentSearchResultsFilter>
}

@observer
export class SelectAccommodation extends React.Component<Props, {}> {
  static contextType = AppContext
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action private setToNotNow = () => {
    this.props.model.values.checkedIn = 'not now'
  }
  @action private setToNow = () => {
    this.props.model.values.checkedIn = 'now'
  }
  @action private setToAnytime = () => {
    this.props.model.values.checkedIn = 'anytime'
  }

  @action private reset = () => {
    this.props.model.values.compoundId = null
    this.props.model.values.buildingId = null
    this.props.model.values.checkedInBegin = null
    this.props.model.values.checkedInEnd = null
  }

  render() {
    let label = 'Belegung'
    let color: string = 'text-gray-500'
    if (this.props.model.values.compoundId) {
      label =
        hermes.getFromStore<ICompound>(
          `/api/${this.context.instance.id}/accommodations/compounds/${this.props.model.values.compoundId}`,
          false,
        )?.label ?? 'Gewählte Unterkunft'
      color = 'text-blue-500'
    }
    return (
      <Menu as='div' className='flex-content mr-6 cursor-pointer relative'>
        <Menu.Button className={color}>
          {label} <i className='fas fa-caret-down' />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='origin-top-left absolute left-0 mt-2 rounded-md shadow-lg p-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40 w-60'>
            <div className='grid grid-col-2 gap-4'>
              <InputCompound
                className='col-span-2'
                model={this.props.model}
                name='compoundId'
                label='Gelände'
                onlyStamm={this.context.permissions.menu_resident_search < 3}
              />
              <InputBuilding
                className='col-span-2'
                model={this.props.model}
                compoundId={this.props.model.values.compoundId}
                name='buildingId'
                label='Gebäude'
              />
              <div className='flex col-span-2'>
                <Button
                  onClick={this.setToNow}
                  color={
                    this.props.model.values.checkedIn === 'now' ? 'primary' : 'secondary'
                  }
                  outline={this.props.model.values.checkedIn !== 'now'}
                  className='flex-auto'
                  style={{ borderRadius: '6px 0 0 6px', padding: '9px 0' }}
                >
                  Jetzt
                </Button>
                <Button
                  onClick={this.setToNotNow}
                  color={
                    this.props.model.values.checkedIn === 'not now'
                      ? 'primary'
                      : 'secondary'
                  }
                  outline={this.props.model.values.checkedIn !== 'not now'}
                  className='flex-auto'
                  style={{
                    borderRadius: '0',
                    padding: '9px 0',
                    borderLeft: 'none',
                    borderRight: 'none',
                  }}
                >
                  Früher
                </Button>
                <Button
                  onClick={this.setToAnytime}
                  color={
                    this.props.model.values.checkedIn === 'anytime'
                      ? 'primary'
                      : 'secondary'
                  }
                  outline={this.props.model.values.checkedIn !== 'anytime'}
                  className='flex-auto'
                  style={{ borderRadius: '0 6px 6px 0', padding: '9px 0' }}
                >
                  Alle
                </Button>
              </div>
              {(null as any) && (
                <InputDate
                  model={this.props.model}
                  name='checkedInBegin'
                  label='Zwischen Datum'
                />
              )}
              {(null as any) && (
                <InputDate
                  model={this.props.model}
                  name='checkedInEnd'
                  label='und Datum'
                />
              )}

              <Menu.Item>
                <div className='col-span-2 flex'>
                  <Button
                    color='secondary'
                    outline
                    className='flex-auto'
                    onClick={this.reset}
                    style={{ borderRadius: '6px 0 0 6px' }}
                  >
                    Zurücksetzen
                  </Button>
                  <Button
                    color='primary'
                    className='flex-content'
                    style={{ borderRadius: '0 6px 6px 0' }}
                  >
                    OK
                  </Button>
                </div>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
