import * as React from 'react'
import { EmployeeCommunicationSidebar } from './components/EmployeeCommunicationSidebar'
import { Model } from 'components/Form/Model'
import { Callout } from 'components/Callout'
import { useLocation } from 'react-router-dom'
import { EmployeeCommunicationContent } from './components/EmployeeCommunicationContent'
const rx = /^\/employee\/communication\/([0-9]+)$/

interface Props {}

export const EmployeeCommunication: React.FC<Props> = (props) => {
  const location = useLocation()
  const match = location.pathname.match(rx)
  const topicId = match?.[1] || undefined
  const [filter] = React.useState(
    () =>
      new Model<{ companyId: string; page: string }>({ companyId: '', page: '0,100' }),
  )

  return (
    <div className='min-h-screen bg-gray-100'>
      {/* Side bar */}
      <div className='absolute top-20 left-6 w-[300px] bottom-6 bg-white rounded-md shadow p-4 overflow-x-hidden overflow-y-auto'>
        <EmployeeCommunicationSidebar filter={filter} selected={topicId} />
      </div>

      {/* Main content */}
      {topicId && <EmployeeCommunicationContent key={topicId} topicId={topicId} />}

      {/* Placeholder */}
      {!topicId && (
        <div className='absolute top-20 left-[348px] right-6 bottom-6 bg-white rounded-md shadow overflow-x-hidden overflow-y-auto flex flex-col'>
          <Callout
            title='Gehaltszettel Vorschau'
            subtitle='Wählen Sie einen Monat aus.'
            icon='fas fa-file-invoice-dollar'
            className='mt-10'
          />
        </div>
      )}
    </div>
  )
}
