import { Collection } from '@byll/hermes'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { IEmployeeCommThread } from 'contracts/employeeComm/interfaces/IEmployeeCommThread'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'

interface Props {
  topicId: string
}

@observer
export class EmployeeCommunicationContent extends React.Component<Props, {}> {
  static contextType = AppContext
  private threads: Collection<IEmployeeCommThread>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.threads = new Collection(
      `/api/${context.instance.id}/employeeComm/topics/${props.topicId}/threads`,
    )
  }

  componentDidMount(): void {
    this.disposers.push(this.threads.init({ readOnly: true }))
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  render() {
    return (
      <div className='absolute top-20 left-[348px] right-6 bottom-6 overflow-x-hidden overflow-y-auto flex flex-col gap-4'>
        {this.threads.resources?.map((t) => (
          <div key={t.id} className='bg-white rounded-md shadow p-4'>
            Gehaltszettel vom {t.data?.subject}
            <br />
            {t.data?.status === 'draft' && (
              <span className='inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10'>
                Inhaltsprüfung fehlgeschlagen
              </span>
            )}
            {t.data?.status === 'open' && (
              <span className='inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10'>
                Versandfertig
              </span>
            )}
          </div>
        ))}
      </div>
    )
  }
}
